import Toast from "components/Toast";
import * as Sentry from "@sentry/react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import React, { useState, useEffect } from "react";
import useDocumentTitle from "../../useDocumentTitle";
import AuthNavbar from "components/Navbars/AuthNavbar";
import LoginFooter from "components/Footers/LoginFooter.js";

function Login() {
  const [error, setError] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();
  useDocumentTitle(`${t("auth.login")} | ${t("site-name")}`);
  const notify = React.useCallback((type, message) => {
    Toast({ type, message });
  }, []);

  const token = document.cookie
    .split(";")
    .find((cookie) => {
      return cookie.startsWith("simba_id");
    })
    ?.split("=")[1];

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    // Check if user is logged in and push to dashboard
    fetch("/jwt/verify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      signal,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.auth === true) {
          notify(
            "error",
            "You already have an active session. Please log out first"
          );

          setTimeout(() => {
            history.push("/dashboard");
          }, 1000);
        }
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          console.log("Aborted");
        }
      });

    return () => {
      controller.abort();
    };
  }, [history, notify, token]);

  const Login = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    const data = {
      email: email,
      password: password,
    };
    fetch("/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((data) => {
        Sentry.setUser({ id: data.user_id });
        e.target.email.value = "";
        e.target.password.value = "";
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <>
      <AuthNavbar />

      <div
        className="row align-items-center justify-content-center my-3 mx-auto"
        style={{ minHeight: "60vh", fontFamily: "Inter", fontWeight: "400" }}
      >
        {!token && (
          <div className="col-12 col-md-5 col-lg-4">
            <div className="card bg-simbalotto-green shadow border-0">
              <div className="card-header bg-transparent pb-5">
                <div className="text-center mt-2 mb-3">
                  <img
                    alt="Onlinelotto365 Logo"
                    className="img-fluid"
                    src={require("assets/img/simbalotto-logo.png")}
                    style={{ width: "200px" }}
                  />
                  <p className="text-white mt-3">{t("auth.login")}</p>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      <strong>Oh snap!</strong> {error}
                    </div>
                  )}
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={Login}>
                  <div className="form-group mb-3">
                    <label
                      className="form-control-label text-white text-capitalize"
                      htmlFor="email"
                    >
                      {t("auth.email")}
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="email@onlinelotto365.com"
                      style={{ color: "black" }}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label
                      className="form-control-label text-white text-capitalize"
                      htmlFor="password"
                    >
                      {t("auth.password")}
                    </label>
                    <input
                      className="form-control"
                      id="password"
                      type="password"
                      placeholder="********"
                    />
                  </div>
                  <div className="text-center">
                    <button
                      className="btn btn-simbalotto-orange text-dark my-4 btn-block"
                      type="submit"
                    >
                      {t("auth.login")}
                    </button>
                  </div>

                  <div className="text-center">
                    <a href="https://onlinelotto365.com/reset/">
                      {t("auth.forgot-password")}
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="row m-auto">
        <div className="col-12">
          <LoginFooter />
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
      />
    </>
  );
}

export default Login;
